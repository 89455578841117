import React, { useState, useRef, useEffect } from 'react';
import { Box, Typography, Grid, TextField, Button, Grid2, TableContainer, Paper, Table, TableBody, TableCell, TableHead, TableRow, Container } from '@mui/material';
import axios from "axios";
import { CSVLink, CSVDownload } from "react-csv";
import moment from 'moment';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
const DashboardPage = () => {
    function createData(name, calories, fat, carbs, protein, packages, linked, Date, Start_Time, End_Time) {
        return { name, calories, fat, carbs, protein, packages, linked, Date, Start_Time, End_Time };
    }
    const [userlist, setuserlist] = useState([])
    const GetFormData = () => {
        axios.get(`https://synergytool.pranathiss.com/qxbox/save-user/`).then((res) => {
            if (res.data) {
                //handleClickOpen(true)
                setuserlist(res.data)
            }
        }).catch((e) => {
            console.log(e)
        })

    }
    useEffect(() => {
        GetFormData()
    }, [])
    const rows = [
        createData('Frozen yoghurt', 159, 6.0, 24, 4.0, 24, 4.3, 1, 2, 5),
        createData('Ice cream sandwich', 237, 9.0, 37, 4.3, 24, 4.3, 1, 2, 5),
        createData('Eclair', 262, 16.0, 24, 6.0, 24, 4.3, 1, 2, 5),
        createData('Cupcake', 305, 3.7, 67, 4.3, 24, 4.3, 1, 2, 5),
        createData('Gingerbread', 356, 16.0, 49, 3.9, 24, 4.3, 1, 2, 5),
        createData('Frozen yoghurt', 159, 6.0, 24, 4.0, 24, 4.3, 1, 2, 5),
        createData('Ice cream sandwich', 237, 9.0, 37, 4.3, 24, 4.3, 1, 2, 5),
        createData('Eclair', 262, 16.0, 24, 6.0, 24, 4.3, 1, 2, 5),
        createData('Cupcake', 305, 3.7, 67, 4.3, 24, 4.3, 1, 2, 5),
        createData('Gingerbread', 356, 16.0, 49, 3.9, 24, 4.3, 1, 2, 5),
    ];
    const [startTime, setstartTime] = useState()
    const [endTime, setendTime] = useState()
    const [selectedDate, setselectedDate] = useState()
    const handleDateChange = (date) => {
        setselectedDate(date)
        setuserlist([])
        axios.get(`https://synergytool.pranathiss.com/qxbox/save-user/${date && `?date=${dayjs(date).format('YYYY-MM-DD')}`}`).then((res) => {
            if (res.data) {
                //handleClickOpen(true)
                setuserlist([...res.data])
            }
        }).catch((e) => {
            console.log(e)
        })
    }
    const handleStartTimeChange = (start, end) => {

        console.log(start)
       
        if(start && end){
            setuserlist([])
            axios.get(`https://synergytool.pranathiss.com/qxbox/save-user/${selectedDate && `?date=${moment(selectedDate).format('YYYY-MM-DD')}`}${start && `&from_time=${dayjs(start).format('HH:mm') }`}${end && `&to_time=${dayjs(end).format('HH:mm')  }`}`).then((res) => {
                if (res.data) {
                    //handleClickOpen(true)
                    setuserlist([...res.data])
                }
            }).catch((e) => {
                console.log(e)
            })
        }
        
    }
    return (
        <>
            <div className='backgroundColor'>
                <Container >
                    <Grid container  >
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} textAlign={"center"}>
                            <Link to={'/'}><img src="/img/pss-final-logo-01-1.png" width='300px' /></Link>
                            <div className='exportButon'>
                                <CSVLink data={userlist} className='exportbuttonnew'  headers={[
                                    { label: "Date", key: "created_at" },
                                  { label: "Full Name", key: "first_name" },
                                  {  label: "Last Name", key: "last_name", },
                                  { label: "Company Name", key: "company_name" },
                                  { label: "Email Address", key: "email", },
                                  { label: "Phone Number", key: "mobile_number", },
                                  { label: "Country", key: "country", },
                                  { label: "Zip Code",  key: "postal_code", },
                                  
                                ]} filename={"Usersdata.csv"} ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cloud-arrow-down-fill" viewBox="0 0 16 16">
                                    <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2m2.354 6.854-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5a.5.5 0 0 1 1 0v3.793l1.146-1.147a.5.5 0 0 1 .708.708" />
                                </svg> Export</CSVLink>
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} textAlign={"center"}  >
                            <Grid container      textAlign={"right"} sx={{  background:"#f0d6d6", border:"2px solid rgb(0, 0, 83)", marginBottom:"15px", padding:"10px", borderRadius:"10px" }}>
                            
                                <Grid item  xs={12} sm={6} md={6} lg={12} textAlign={"right"} sx={{display:"flex", alignItems:"end", gap:"10px", justifyContent: "flex-end"}}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DemoContainer components={['DatePicker']}>
                                        <DatePicker fullwidth className="datepickerlocal" label="Date" value={selectedDate} onChange={handleDateChange} />
                                    </DemoContainer>
                                    </LocalizationProvider>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['TimePicker']}>
                                        <TimePicker className="datepickerlocal"  label="Start Time" value={startTime} onChange={(e)=>{handleStartTimeChange(e, endTime); setstartTime(e)}} />
                                    </DemoContainer>
                                    </LocalizationProvider>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['TimePicker']}>
                                        <TimePicker className="datepickerlocal"  label="End Time" value={endTime} onChange={(e)=>{handleStartTimeChange(startTime, e); setendTime(e)}} />
                                    </DemoContainer>
                                    </LocalizationProvider>
                                  
                                </Grid>
                                 
                            </Grid>
                            <TableContainer   sx={{ overflow: 'auto', maxHeight: 550, border:"2px solid rgb(0, 0, 83)", borderRadius:"10px" }} >
                                <Table stickyHeader aria-label="sticky table" sx={{
                                            whiteSpace: 'nowrap',
                                        }} >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell >Date</TableCell>
                                            <TableCell>  First Name</TableCell>
                                            <TableCell  >Last Name</TableCell>
                                            <TableCell  >Company Name</TableCell>
                                            <TableCell  >Email ID</TableCell>
                                            <TableCell >Phone Number</TableCell>
                                            <TableCell >Country</TableCell>
                                            <TableCell >Zip Code</TableCell>
                                            
                                             
                                        </TableRow>
                                    </TableHead>
                                    <TableBody sx={{background:"#ececec"}}>
                                        {userlist.map((row) => (
                                            <TableRow
                                                key={row.first_name}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell >{moment(row.created_at).format('DD-MM-YYYY') }</TableCell>
                                                <TableCell component="th" scope="row">
                                                    {row.first_name}
                                                </TableCell>
                                                <TableCell >{row.last_name}</TableCell>
                                                <TableCell >{row.company_name}</TableCell>
                                                <TableCell >{row.email}</TableCell>
                                                <TableCell >{row.mobile_number}</TableCell>
                                                <TableCell >{row.country}</TableCell>
                                                <TableCell >{row.postal_code}</TableCell>
                                                
                                                
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Button component={Link} to={'/'} className='dashboardback'>Back</Button>
                        </Grid>


                    </Grid>
                </Container>

            </div>
        </>
    )
}

export default DashboardPage