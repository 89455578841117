import React from "react";
import ReactDOMClient from "react-dom/client";
import { Frame } from "./screens/Frame";
import FrameNew from "./screens/Frame/frameNew";
import { BrowserRouter as Router, Route, Routes, } from "react-router-dom";
import DashboardPage from "./screens/Frame/Dashboard";
const app = document.getElementById("app");
const root = ReactDOMClient.createRoot(app);
// root.render(<Frame />);
root.render(
    <Router>
      <Routes>
        <Route path="/" element={<FrameNew />} />
        <Route path="/:version" element={<FrameNew />} />
        <Route path="/dashboard" element={<DashboardPage />} />
      </Routes>
    </Router>


);
